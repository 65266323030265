import * as React from 'react';
import styled from 'styled-components';
import { CondData, Part, Tutti } from '../lib/tutti';
import { useAnimationFrame } from '../lib/utils';
import songData from '../config/songdata';
import { PartSys } from './PartSys';

// constants
const nowBarPct = 0.7; // location of nowBar Y coordinate (normalized)

interface Gem {
  start: number;
  end: number;
}

interface Props {
  condData: CondData;
  tutti: Tutti;
}

export const Live = (props: Props) => {
  const { tutti, condData } = props;

  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const tracks = React.useRef<Tracks | null>(null);

  const draw = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // resize canvas if needed and make new Tracks object
    if (
      !tracks.current ||
      canvas.width !== window.innerWidth ||
      canvas.height !== window.innerHeight
    ) {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      tracks.current = new Tracks(canvas);
    }

    // calculate elapsed time
    let time = -100;
    const now = tutti.getClock().getTime();
    if (condData.startTime > 0 && now) time = now - condData.startTime;

    // clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // draw1 tracks
    tracks.current.draw1(time);

    // draw now bar
    drawNowBar(canvas, ctx);

    // draw2 tracks (particle system)
    tracks.current.draw2();
  };

  useAnimationFrame(draw);

  return (
    <StyledLive>
      <h1>Tutti</h1>
      <canvas ref={canvasRef}></canvas>
      <p className='byLine'>
        Engineered Engineers
        <br />
        by Evan Ziporyn
      </p>
    </StyledLive>
  );
};

const StyledLive = styled.div`
  canvas {
    position: absolute;
    width: 100%;
    top: 0;
  }

  .byLine {
    position: absolute;
    bottom: 2%;
    left: 5%;

    font-family: 'SuisseIntl';
    font-size: 125%;
  }
`;

class Track {
  canvas;
  ctx;
  color;
  gems;
  idx;
  nowBarY;
  tpM;
  tpB;
  pixPerBeat;
  x1;
  w;
  ps;

  constructor(canvas: HTMLCanvasElement, gemsTxt: string, color: string, idx: number) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d')!;
    this.color = color;
    this.gems = parseGems(gemsTxt, (60 * songData.beatsPerUnit) / songData.bpm);
    this.idx = idx;
    this.nowBarY = canvas.height * nowBarPct;

    // for timeToPos equation
    this.tpM = (0 - this.nowBarY) / songData.timeSpan; // slope
    this.tpB = this.nowBarY; // y-intercept
    this.pixPerBeat = (this.nowBarY / songData.timeSpan / songData.bpm) * 60;

    // x-coordinates of the track
    const spacing = canvas.width * 0.16; // space between tracks
    const trackW = canvas.width * 0.065; // track width
    const start = canvas.width / 2 - 1.5 * spacing; // assumes 4 tracks
    this.x1 = start + spacing * this.idx - trackW / 2; // left-coord of track
    this.w = trackW;

    // particle system at nowbar:
    const clrArray = hexToRGBArray(color);
    const psClrArray = clrArray.map((v) => Math.round(v * 0.75)); // slightly darker color
    const psh = 30; // particle system height
    this.ps = new PartSys(this.x1, this.nowBarY - psh / 2, this.w, psh, psClrArray);
  }

  timeToPos(gemTime: number, nowTime: number) {
    return (gemTime - nowTime) * this.tpM + this.tpB;
  }

  draw1(nowTime: number) {
    let active = false;

    // draw gems
    for (const gem of this.gems) {
      const start = this.timeToPos(gem.start, nowTime);
      const end = this.timeToPos(gem.end, nowTime);

      // only draw gems when they are visible on screen
      if (start > 0 && end < this.canvas.height) {
        this.drawGem(start, end);
      }

      // active (gem is hitting now bar) is any gem is intersecting the now bar
      active = active || (gem.start < nowTime && nowTime < gem.end);
    }

    // if active, go particle system!
    const emitRate = 2;
    this.ps.setEmitRate(active ? emitRate : 0);
  }

  draw2() {
    // draw particles
    this.ps.draw(this.ctx);
  }

  drawGem(y2: number, y1: number) {
    const ctx = this.ctx;

    // colored part (above now bar)
    if (y1 < this.nowBarY) {
      ctx.fillStyle = this.color;
      const h = Math.min(y2, this.nowBarY) - y1;
      ctx.fillRect(this.x1, y1, this.w, h);
    }

    // gray part (below now bar)
    if (y2 > this.nowBarY) {
      ctx.fillStyle = 'rgb(50, 50, 50)';
      y1 = Math.max(y1, this.nowBarY);
      const h = y2 - y1;
      ctx.fillRect(this.x1, y1, this.w, h);
    }
  }
}

class Tracks {
  tracks: Track[];

  constructor(canvas: HTMLCanvasElement) {
    this.tracks = songData.parts.map((part, idx) => {
      const p = part as Part;
      const color = songData.colors[p];
      const gems = songData.gems[p];
      return new Track(canvas, gems, color, idx);
    });
  }

  draw1(time: number) {
    for (const track of this.tracks) track.draw1(time);
  }

  draw2() {
    for (const track of this.tracks) track.draw2();
  }
}

// helper functions:
function drawNowBar(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
  const nowBarY = canvas.height * nowBarPct;
  const nowBarX1 = canvas.width * 0.05;
  const nowBarX2 = canvas.width - nowBarX1;

  ctx.strokeStyle = 'white';
  ctx.fillStyle = 'white';
  ctx.lineWidth = 10;
  ctx.lineCap = 'round';
  ctx.beginPath();
  ctx.moveTo(nowBarX1, nowBarY);
  ctx.lineTo(nowBarX2, nowBarY);
  ctx.stroke();
}

function hexToRGBArray(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    : [0, 0, 0];
}

//----------------------------------
// convert a txt string into a timed listing of gems.
//
export function parseGems(txt: string, secondsPerUnit: number) {
  let gems: Gem[] = [];

  let prevMark = ' ';
  for (let i = 0; i < txt.length; i++) {
    // create a new gem:
    if (prevMark === ' ' && txt[i] !== ' ') {
      gems.push({ start: i, end: -1 });
    }

    // terminate the current gem:
    if (prevMark !== ' ' && txt[i] === ' ') {
      gems[gems.length - 1].end = i;
    }

    prevMark = txt[i];
  }

  // terminate the last gem:
  if (gems.length && gems[gems.length - 1].end === -1) {
    gems[gems.length - 1].end = txt.length;
  }

  // convert gem "units" into seconds:
  for (let i = 0; i < gems.length; i++) {
    gems[i].start *= secondsPerUnit;
    gems[i].end *= secondsPerUnit;
  }

  return gems;
}

import * as React from 'react';
import styled from 'styled-components';

import { Page } from './Page';
import { Header } from './Header';
import { Footer } from './Footer';
import { Part } from '../lib/tutti';
import { partDisplayName } from '../lib/utils';

interface Props {
  // to show for bravo text
  part: Part | null;
}

export const Done = (props: Props) => {
  const { part } = props;

  const name = part ? partDisplayName[part] : 'Tutti';
  const text = `Bravo, ${name}!`;

  return (
    <Page>
      <Header />
      <Main>
        <span>{text}</span>
      </Main>
      <Footer bulletNum={2} />
    </Page>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  span {
    font-family: 'SuisseIntlMono';
    font-size: 300%;
  }
`;

import * as React from 'react';
import styled from 'styled-components';

import { ReactComponent as Hand } from '../img/hand.svg';

import { ReactComponent as Marimba } from '../img/marimba.svg';
import { ReactComponent as Glockenspiel } from '../img/glockenspiel.svg';
import { ReactComponent as Vibraphone } from '../img/vibraphone.svg';
import { ReactComponent as Flute } from '../img/flute.svg';
import { ReactComponent as Clarinet } from '../img/clarinet.svg';
import { ReactComponent as Oboe } from '../img/oboe.svg';
import { ReactComponent as Horn } from '../img/horn.svg';
import { ReactComponent as Trumpet } from '../img/trumpet.svg';
import { ReactComponent as Trombone } from '../img/trombone.svg';
import { ReactComponent as Violin } from '../img/violin.svg';
import { ReactComponent as Viola } from '../img/viola.svg';
import { ReactComponent as Cello } from '../img/cello.svg';
import { Inst } from '../lib/tutti';
import { partOfInst } from '../lib/utils';
import { FlyingNotes } from './FlyingNotes';

interface Props {
  /** what the button should look like */
  type: Inst | 'hand';
  /** show flying notes on active? */
  animate: boolean;
  /** called on button press */
  onDown?: () => void;
  /** called on button release */
  onUp?: () => void;
}

const iconMap = {
  hand: <Hand className='buttonIcon hand' />,
  glockenspiel: <Glockenspiel className='buttonIcon inst' />,
  marimba: <Marimba className='buttonIcon inst' />,
  vibraphone: <Vibraphone className='buttonIcon inst' />,
  horn: <Horn className='buttonIcon inst' />,
  trumpet: <Trumpet className='buttonIcon inst' />,
  trombone: <Trombone className='buttonIcon inst' />,
  clarinet: <Clarinet className='buttonIcon inst' />,
  flute: <Flute className='buttonIcon inst' />,
  oboe: <Oboe className='buttonIcon inst' />,
  violin: <Violin className='buttonIcon inst' />,
  viola: <Viola className='buttonIcon inst' />,
  cello: <Cello className='buttonIcon inst' />,
};

interface ColorScheme {
  color: string;
  light: string;
  dark: string;
}

const colorMap = {
  hand: { color: '#444', light: 'white', dark: '#bfbfbf' },
  strings: { color: '#80141c', light: '#e03642', dark: '#aa1a24' },
  perc: { color: '#5a6100', light: '#cedd00', dark: '#828b00' },
  winds: { color: '#004f65', light: '#00a3d2', dark: '#006380' },
  brass: { color: '#a80', light: '#ffd428', dark: '#d5aa00' },
};

export const PlayButton = (props: Props) => {
  const { type, animate, onDown, onUp } = props;
  const [active, setActive] = React.useState(false);

  const icon = iconMap[type];
  const part = type === 'hand' ? 'hand' : partOfInst(type)!;
  const colors = colorMap[part];

  const noTouch = !('ontouchstart' in document.documentElement);

  const ref = React.useRef<HTMLButtonElement>(null);
  const midpoint = React.useRef({ x: 0, y: 0, w: 100, h: 100 });
  const down = () => {
    // on button-down, grab button midpoint for FlyingNotes:
    const rect = ref.current?.getBoundingClientRect();
    if (rect) {
      const x = Math.round((rect.left + rect.right) / 2);
      const y = Math.round((rect.top + rect.bottom) / 2);
      const w = window.innerWidth;
      const h = window.innerHeight;
      midpoint.current = { x, y, w, h };
    }

    setActive(true);
    onDown?.();

    document.addEventListener('touchend', () => up(), { once: true });

    if (noTouch) document.addEventListener('mouseup', () => up(), { once: true });
  };

  const up = () => {
    setActive(false);
    onUp?.();
  };

  const { x, y, w, h } = midpoint.current;

  return (
    <>
      <FlyingNotes active={active && animate} x={x} y={y} w={w} h={h} />
      <Button
        ref={ref}
        colors={colors}
        onTouchStart={down}
        onMouseDown={noTouch ? down : undefined}
      >
        <div className='buttonDown'></div>
        {icon}
      </Button>
    </>
  );
};

const Button = styled.button<{ colors: ColorScheme }>`
  // button
  position: relative;
  width: 50vmin;
  height: 50vmin;
  border: none;
  outline: none;
  border-radius: 9999em;

  background-image: linear-gradient(
    to bottom,
    ${(p) => p.colors.light},
    ${(p) => p.colors.dark}
  );

  // only appears when active
  .buttonDown {
    position: absolute;
    border-radius: 9999em;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    background-image: linear-gradient(
      to top,
      ${(p) => p.colors.light},
      ${(p) => p.colors.dark}
    );
    opacity: 0;
  }

  // the hand icon the middle
  .buttonIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${(p) => p.colors.color};
  }

  .hand {
    width: 30%;
    height: 30%;
  }

  .inst {
    width: 55%;
    height: 55%;
  }

  // also for the hand icon in the middle
  .alt-color {
    color: transparent;
  }

  // changes when active:
  :active {
    .hand {
      width: 28%;
      height: 28%;
    }

    .inst {
      width: 52%;
      height: 52%;
    }

    .alt-color {
      color: ${(p) => p.colors.color};
    }

    .buttonDown {
      opacity: 1;
    }
  }
`;

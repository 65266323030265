import * as React from 'react';
import styled from 'styled-components';

import { ReactComponent as Clarinet } from '../img/clarinet.svg';
import { ReactComponent as Horn } from '../img/horn.svg';
import { ReactComponent as Violin } from '../img/violin.svg';
import { ReactComponent as Marimba } from '../img/marimba.svg';
import { ReactComponent as Bell } from '../img/bell.svg';
import { ReactComponent as Volume } from '../img/volume.svg';
import { ReactComponent as Wifi } from '../img/wifi.svg';

import { useInterval } from '../lib/utils';
import { CondData, defaultStatus, StatusData, Tutti } from '../lib/tutti';

interface Props {
  condData: CondData;
  tutti: Tutti;
}

export const Setup = (props: Props) => {
  const { tutti, condData } = props;

  // local state for stats as they get updated.
  const [stats, setStats] = React.useState<StatusData>(defaultStatus);

  // update stats by asking tutti server every 1000ms.
  useInterval(() => {
    tutti.getStatusCounts((data: StatusData) => setStats(data));
  }, 1000);

  // get inst counts
  const { perc, brass, winds, strings } = stats.part;

  // get wifi name
  const wifi = condData.wifiText;

  return (
    <StyledSetup>
      <h1>Tutti</h1>

      <div className='instructions'>
        <h2>tutti.mit.edu</h2>

        <img src='tutti_qr.png' />

        <ul>
          <li>
            <Wifi className='glyphIcon' />
            <span>
              WIFI: <b>{wifi}</b>
            </span>
          </li>
          <li>
            <Bell className='glyphIcon' />
            <span>
              Ringer: <b>ON</b>
            </span>
          </li>
          <li>
            <Volume className='glyphIcon' />
            <span>
              Volume: <b>100%</b>
            </span>
          </li>
        </ul>
      </div>

      <div className='instPanel'>
        <div>
          <Clarinet className='instIcon' /> <span className='blue'>{winds || 0}</span>
        </div>
        <div>
          <Horn className='instIcon' /> <span className='yellow'>{brass || 0}</span>
        </div>
        <div>
          <Marimba className='instIcon' /> <span className='green'>{perc || 0}</span>
        </div>
        <div>
          <Violin className='instIcon' /> <span className='red'>{strings || 0}</span>
        </div>
      </div>
    </StyledSetup>
  );
};

const StyledSetup = styled.div`
  // no dots in front of lists
  ul {
    list-style-type: none;
  }

  h2 {
    font-size: 225%;
    margin: 10px;
  }

  .instructions {
    // make this div vertically centered
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    width: 100%;

    // items in this div will be horizontally spaced out
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    img {
      height: 50vh;
      width: 50vh;
      margin-left: 10px;
    }

    // alignment for WIFI, Ringer, Volume, etc...
    li {
      font-size: 175%;
      font-family: 'SuisseIntl';
      margin-top: 10px;
      display: flex;
      flex-direction: row;
    }

    svg path,
    g {
      fill: currentColor;
    }
  }

  // row of inst icons and counters
  .instPanel {
    position: absolute;
    width: 100%;
    bottom: 10%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    div {
      display: flex;
      font-size: 200%;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }

  // icon for ringer, volume, etc...
  .glyphIcon {
    width: 40px;
    height: 40px;
    margin-right: 30px;
  }

  // instrument icons
  .instIcon {
    width: 100px;
    height: 100px;
    margin: 10px;
  }

  // instrument icon colors
  .blue {
    color: #004f65;
  }

  .yellow {
    color: #aa8800;
  }

  .red {
    color: #80141c;
  }

  .green {
    color: #727a00;
  }
`;

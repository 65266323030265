import * as React from 'react';
import styled from 'styled-components';
import { Part } from '../lib/tutti';
import { Footer } from './Footer';
import { PartButton } from './PartButton';
import { Header } from './Header';
import { Page } from './Page';

interface Props {
  /** the currently selected part */
  part: Part | null;
  /** callback when new part is chosen */
  onSelect: (part: Part) => void;
  /** inform that we just entered  */
  onSetStatus: (status: string) => void;
}

export const PartSelect = (props: Props) => {
  const { part, onSelect, onSetStatus } = props;

  // inform of status on mount
  React.useEffect(() => onSetStatus('choose'), [onSetStatus]);

  const showNext = part !== null;

  return (
    <Page>
      <Header />
      <Main>
        <p>Select Your Orchestra Section</p>
        <ButtonGroup>
          <PartButton part={'perc'} selected={part === 'perc'} onSelect={onSelect} />
          <PartButton part={'brass'} selected={part === 'brass'} onSelect={onSelect} />
          <PartButton part={'winds'} selected={part === 'winds'} onSelect={onSelect} />
          <PartButton part={'strings'} selected={part === 'strings'} onSelect={onSelect} />
        </ButtonGroup>
      </Main>
      <Footer bulletNum={1} showNext={showNext} nextUrl={'/inst'} />
    </Page>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  p {
    font-size: 125%;
    // top right bottom left
    margin: 3em 2% 3em 3%;
  }

  @media (orientation: landscape) {
    flex-direction: row;
    p {
      max-width: 25%;
    }
  }
`;

const ButtonGroup = styled.div`
  display: grid;
  grid: auto auto / auto auto;
  justify-content: center;
  align-items: center;
  height: auto;
`;

import * as React from 'react';
import styled from 'styled-components';
import { ReactComponent as Bell } from '../img/bell.svg';
import { ReactComponent as Volume } from '../img/volume.svg';

export const RingerVolume = () => {
  return (
    <StyledRingerVolume>
      <p>
        <Bell className='icon' />
        <span>
          Ringer: <b>ON</b>
        </span>
      </p>

      <p>
        <Volume className='icon' />
        <span>
          Volume: <b>100%</b>
        </span>
      </p>
    </StyledRingerVolume>
  );
};

const StyledRingerVolume = styled.div`
  p {
    margin: 10px;
    display: flex;
    flex-direction: row;
  }
  .icon {
    width: 25px;
    height: 25px;
    margin-right: 25px;
  }
`;

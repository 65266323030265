import * as React from 'react';
import { Header } from './Header';
import { Page } from './Page';
import styled from 'styled-components';
import { Footer } from './Footer';
import { CondData, Inst, Tutti } from '../lib/tutti';
import { PlayButton } from './PlayButton';
import { partOfInst, useAnimationFrame } from '../lib/utils';
import { LoadingBar } from './LoadingBar';
import { Song, TestTone } from '../lib/audio';
import { Done } from './Done';

interface Props {
  /** Tutti object */
  tutti: Tutti;
  /** Cond Data */
  condData: CondData;
  /** the chosen instrument */
  inst: Inst;
  /** inform of our status */
  onSetStatus: (status: string) => void;
}

const instName = {
  glockenspiel: 'Glockenspiel',
  marimba: 'Marimba',
  vibraphone: 'Vibraphone',
  horn: 'French Horn',
  trumpet: 'Trumpet',
  trombone: 'Trombone',
  clarinet: 'Clarinet',
  flute: 'Flute',
  oboe: 'Oboe',
  violin: 'Violin',
  viola: 'Viola',
  cello: 'Cello',
};

export const Instrument = (props: Props) => {
  const { inst, tutti, condData, onSetStatus } = props;

  const [playing, setPlaying] = React.useState(false);

  const song = React.useRef<Song | null>(null);
  const loop = React.useRef<TestTone | null>(null);

  // manage song loading
  React.useEffect(() => {
    console.log('loading song and loop');
    const getTime = () => tutti.getClock().getTime();
    song.current = new Song(getTime, `audio/score/${inst}.mp3`);
    loop.current = new TestTone(`audio/loops/${inst}.mp3`);

    return () => {
      console.log('deleting song and loop');
      if (song.current) {
        song.current.release();
        song.current = null;
      }
      if (loop.current) {
        loop.current = null;
      }
    };
  }, [inst, tutti]);

  // loading progress
  const [loadingPct, setLoadingPct] = React.useState(0);
  useAnimationFrame(() => {
    const sc = song.current;
    const songProg = sc ? sc.getLoadProgress() : 0;
    const clockProg = tutti.getClock().getLoadProgress();
    const prog = 0.5 * (songProg + clockProg);
    if (prog !== loadingPct) {
      onSetStatus(prog === 1 ? 'ready' : 'loading');
      setLoadingPct(prog);
    }
  });

  // based on condData.playing, change between song or loop being active.
  React.useEffect(() => {
    console.log('condData StartTime', condData.startTime);

    if (condData.startTime !== 0) {
      console.log(`song.start(${condData.startTime})`);
      song.current?.start(condData.startTime);
      loop.current?.kill();
    } else {
      console.log('song.stop()');
      song.current?.stop();
      loop.current?.resume();
    }
    if (condData.mode === 'done') {
      // make sure everything is turned off when 'done'
      song.current?.stop();
      loop.current?.kill();
      setPlaying(false);
    }
  }, [condData.startTime, condData.mode]);

  const down = () => {
    // don't do anything if loading is not done.
    if (loadingPct !== 1) return;

    setPlaying(true);
    loop.current?.start();
    song.current?.play(true);
  };

  const up = () => {
    // don't do anything if loading is not done.
    if (loadingPct !== 1) return;

    setPlaying(false);
    loop.current?.stop();
    song.current?.play(false);
  };

  const loaded = loadingPct === 1;
  const part = partOfInst(inst);
  const activePart = (playing && part) || undefined;
  const name = instName[inst];

  // display done screen
  if (condData.mode === 'done') {
    return <Done part={part} />;
  }

  return (
    <Page activePart={activePart}>
      <Header />
      <Main>
        <div>
          <h1>{name}</h1>
          <p>
            Press + Hold to play
            <br /> Release to stop
          </p>
        </div>
        <div className='play-load'>
          <PlayButton type={inst} animate={loaded} onDown={down} onUp={up} />
          <LoadingBar pct={loadingPct} />
        </div>
      </Main>
      <Footer bulletNum={2} />
    </Page>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;

  @media (orientation: landscape) {
    flex-direction: row;
  }

  .play-load {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

import * as React from 'react';
import styled from 'styled-components';

import { Page } from './Page';
import { BigHeader } from './Header';
import { RingerVolume } from './RingerVolume';

interface Props {
  onSetStatus?: (status: string) => void;
  onOK?: () => void;
}

export const Intro = (props: Props) => {
  // inform of status on mount
  const { onSetStatus, onOK } = props;
  React.useEffect(() => onSetStatus?.('intro'), [onSetStatus]);

  const noTouch = !('ontouchstart' in document.documentElement);
  const showButton = !!onOK;

  return (
    <Page>
      <Main>
        <BigHeader />
        <p className='helpText'>
          Get ready for an interactive experience <br /> by checking your phone settings
        </p>
        <RingerVolume />
        <Start show={showButton} onTouchEnd={onOK} onClick={noTouch ? onOK : undefined}>
          Start
        </Start>
      </Main>
    </Page>
  );
};

const Start = styled.button<{ show: boolean }>`
  border-radius: 10px;
  margin: 1em;
  padding: 0.5em 0.8em;
  width: 40%;
  height: 10%;
  background: #fff;
  color: #000;
  font-family: 'SuisseIntlMono';
  font-size: 1.5rem;

  visibility: ${(p) => (p.show ? 'visible' : 'hidden')};

  :active {
    transform: scale(120%);
  }
  transition: transform 0.15s;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;

  .helpText {
    color: #ccc;
  }
`;

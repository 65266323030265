export const songData = {
  bpm: 144,
  length: 464,
  beatsPerUnit: 4,
  timeSpan: 8,

  colors: {
    winds: '#0083a9',
    brass: '#fecb00',
    perc: '#919c00',
    strings: '#cd202c',
  },

  gems: {
    winds:
      '    xxxxxxxxxxxxxxxxxx      xx        xx  xx    xxxxxxx      x x x x xx x x x    x  xxxxxxxxx x xx  xxxx            ',
    brass:
      '        xxxxxxxxxxxx    xx      xx  xx  xx  xxxxxxxxxx  x   x  x x x x x x x x  xx  xxxxxxxxx x xx  xxxxxx          ',
    perc: '            xxxxxxxx  xx      xx      xx  xx      xxxx x  x   x x x x  x x x x xxx  xxxxxxxx x x  xx  xxxxxx        ',
    strings:
      '                xxxx      xx      xx  xx  xx        xx   x x   x x x xx x x x xxxx  xxxxxxxx x x  xx  xxxxxxxxxxxx  ',
  },

  parts: ['winds', 'brass', 'perc', 'strings'],
};

export default songData;

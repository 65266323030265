import * as React from 'react';
import styled from 'styled-components';
import { CondData, Tutti } from '../lib/tutti';

interface Props {
  tutti: Tutti;
  condData: CondData;
}

interface State {
  bravo: string;
  wifi: string;
}

export const Params = (props: Props) => {
  const { tutti, condData } = props;

  // state is the current value of the two text fields (default coming from condData)
  const [text, setText] = React.useState<State>({
    bravo: condData.bravoText,
    wifi: condData.wifiText,
  });

  // if condData changes (but only for the text fields we care about), update the values
  React.useEffect(() => {
    setText({ bravo: condData.bravoText, wifi: condData.wifiText });
  }, [condData.bravoText, condData.wifiText]);

  const changeWifi = (e: React.ChangeEvent<HTMLInputElement>) => {
    const wifi = e.target.value;
    setText({ ...text, wifi });
  };

  const changeBravo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const bravo = e.target.value;
    setText({ ...text, bravo });
  };

  // pressing Enter will update that field on the server
  const keyDownWifi = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') tutti.set('wifiText', text['wifi']);
  };

  // pressing Enter will update that field on the server
  const keyDownBravo = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') tutti.set('bravoText', text['bravo']);
  };

  // helper function for updating the clockOffset on the server
  const changeOffset = (delta: number) => {
    tutti.set('clockOffset', condData.clockOffset + delta);
  };

  const toggleClicks = () => tutti.toggle('clickState');

  return (
    <StyledParams>
      <button onClick={() => changeOffset(0.01)}>Offset +</button>
      <button onClick={() => changeOffset(-0.01)}>Offset -</button>
      <span>offset: {condData.clockOffset.toFixed(3)}</span>
      <br />
      <span>bravo</span>
      <input type='text' value={text.bravo} onChange={changeBravo} onKeyDown={keyDownBravo} />
      <span>wifi</span>
      <input type='text' value={text.wifi} onChange={changeWifi} onKeyDown={keyDownWifi} />
      <button onClick={toggleClicks}>clicks</button>
      <span>{condData.clickState ? 'ON' : 'OFF'}</span>
    </StyledParams>
  );
};

const StyledParams = styled.div`
  button {
    margin: 3px;
  }
`;

import { IntervalPoller } from './utils';

// global vars
let gAudioContext: AudioContext | null = null;

const createHtmlAudio = () => {
  const audio = document.createElement('audio');
  audio.setAttribute('x-webkit-airplay', 'deny'); // Disable the iOS control center media widget
  audio.preload = 'auto';
  audio.loop = false;
  audio.src = `audio/silence.wav`;
  audio.load();
  audio.play();
};

//----------------------------------------------
// This is necessary on some browsers, which mutes all sounds until a sound is
// initiated by a touch event:
const enableBrowserAudio = () => {
  // play 50 ms of silence to get browser to unblock audio production
  const touchHandler = () => {
    if (!gAudioContext) return;

    console.log('play-from-click');
    const osc = gAudioContext.createOscillator();
    osc.connect(gAudioContext.destination);
    osc.frequency.value = 0.0;
    osc.start(gAudioContext.currentTime);
    osc.stop(gAudioContext.currentTime + 0.05);

    // playing an HTML audio tag will allow playback even if a phone's ringer is set to mute.
    createHtmlAudio();

    document.body.removeEventListener('touchstart', touchHandler, false);
    document.body.removeEventListener('mousedown', touchHandler, false);
  };

  document.body.addEventListener('touchstart', touchHandler, false);
  document.body.addEventListener('mousedown', touchHandler, false);
};

export const init = () => {
  // Setup Audio Context
  try {
    if (!gAudioContext) {
      gAudioContext = new AudioContext();
      enableBrowserAudio();
    } else console.log('Audio already initialized');
  } catch (e) {
    alert('Web Audio API is not supported in this browser');
  }
};

class Filter {
  y;
  alpha;

  // create filter with alpha value
  constructor(alpha: number) {
    this.alpha = alpha;
    this.y = 0;
  }

  // insert a sample, returning the updated output
  insert = (x: number) => {
    if (Math.abs(x - this.y) > 1) this.y = x;
    this.y = this.alpha * x + (1 - this.alpha) * this.y;
    return this.y;
  };

  // set output value (for resetting filter state)
  set = (y: number) => {
    this.y = y;
  };
}

//----------------------------------------------
// Async load an audio buffer and let callback know when that happened, along with loading progress.
// Could handle errors better, probably.
class BufferLoader {
  constructor(
    url: string,
    loadCB: (buffer: AudioBuffer) => void,
    progCB?: (pct: number) => void,
  ) {
    // Load buffer asynchronously
    const request = new XMLHttpRequest();

    request.onload = () => {
      if (!gAudioContext) return;

      // Asynchronously decode the audio file data in request.response
      gAudioContext.decodeAudioData(
        request.response,
        (buffer) => {
          if (!buffer) {
            alert('error decoding file data: ' + url);
            return;
          }
          loadCB(buffer);
        },
        (error) => {
          console.error('decodeAudioData error', error);
        },
      );
    };

    request.onerror = () => alert('BufferLoader: XHR error');

    if (progCB) {
      request.onprogress = (evt) => progCB(evt.loaded / evt.total);
    }

    request.open('GET', url, true);
    request.responseType = 'arraybuffer';
    request.send();
  }
}

export class TestTone {
  gainNode: GainNode | null;
  buffer: AudioBuffer | null;
  bufferSource: AudioBufferSourceNode | null;
  loadProgress: number;
  active: boolean;

  FADE_TIME = 0.2;

  constructor(filename: string) {
    this.gainNode = null;
    this.buffer = null; //buffer; set as buffer of a buffersource.
    this.bufferSource = null;
    this.loadProgress = 0;
    new BufferLoader(filename, this.finishedLoading, (prog) => {
      this.loadProgress = prog;
    });
    this.active = true;
  }

  finishedLoading = (bufferList: AudioBuffer) => {
    this.buffer = bufferList;
    this.loadProgress = 1;
  };

  start = () => {
    if (!gAudioContext) return;

    if (this.buffer && this.bufferSource === null && this.active) {
      this.gainNode = gAudioContext.createGain();
      this.gainNode.gain.value = 0;
      this.gainNode.connect(gAudioContext.destination);

      this.bufferSource = gAudioContext.createBufferSource();
      this.bufferSource.connect(this.gainNode);
      this.bufferSource.loop = true;
      this.bufferSource.buffer = this.buffer;
      this.bufferSource.start(gAudioContext.currentTime, 0);

      this.gainNode.gain.setValueAtTime(0, gAudioContext.currentTime);
      this.gainNode.gain.linearRampToValueAtTime(
        0.5,
        gAudioContext.currentTime + this.FADE_TIME,
      );
    }
  };

  stop = () => {
    if (!gAudioContext) return;

    // stop with a ramp to 0
    if (this.bufferSource && this.gainNode) {
      this.gainNode.gain.cancelScheduledValues(gAudioContext.currentTime);
      this.gainNode.gain.setValueAtTime(this.gainNode.gain.value, gAudioContext.currentTime);
      this.gainNode.gain.linearRampToValueAtTime(
        0,
        gAudioContext.currentTime + this.FADE_TIME,
      );
      this.gainNode = null;

      this.bufferSource.stop(gAudioContext.currentTime + this.FADE_TIME);
      this.bufferSource = null;
    }
  };

  kill = () => {
    this.stop();
    this.active = false;
  };

  resume = () => {
    this.active = true;
  };
}

// ----------------------------------------------------
// Song class - handles all scheduling, etc...
//
export class Song {
  getTime: () => number;
  buffer: AudioBuffer | null;
  startTime: number;
  loadProgress: number;
  mainNode: GainNode | null;
  activeSource: AudioBufferSourceNode | null;
  poller: IntervalPoller;
  clockDelta: number;
  gainNodes: GainNode[];
  gainNodeIdx: number;
  emptyBuffers: AudioBuffer[];
  emptyBufferSources: AudioBufferSourceNode[];

  driftFilter: Filter;

  // constants
  NORMAL_GAIN = 0.5;
  FADE_TIME = 0.3;
  PLAY_TOGGLE_FADE = 0.05;

  // play empty buffers thru all gain nodes, to work around bug
  // where automation fails when sound isn't playing
  PLAY_EMPTY_BUFFER = true;
  CLOCK_DRIFT_THRESH = 0.035; //seconds
  NUM_GAIN_NODES = 2;

  constructor(getTime: () => number, url: string) {
    console.log('New Song()');
    this.getTime = getTime;
    this.buffer = null;
    this.startTime = 0; // non-zero means playing. zero means not playing
    this.loadProgress = 0.0; // < 1 when loading. === 1 when done.
    this.mainNode = null;
    this.activeSource = null;

    this.driftFilter = new Filter(0.2);

    // called when buffer is done loading.
    const bufLoadDone = (buf: AudioBuffer) => {
      console.log('loaded buf:', buf.duration);
      this.buffer = buf;
      this.loadProgress = 1.0;
    };

    new BufferLoader(url, bufLoadDone, (prog: number) => (this.loadProgress = prog));

    this.poller = new IntervalPoller(this._update, 1000);
    this.clockDelta = 0;

    this.gainNodes = [];
    this.gainNodeIdx = 0;
    this.emptyBuffers = [];
    this.emptyBufferSources = [];

    // Audio Setup
    if (!gAudioContext) return;

    // create main node
    this.mainNode = gAudioContext.createGain();
    this.mainNode.connect(gAudioContext.destination);
    this.mainNode.gain.value = 0;

    // create 2 gain nodes and connect to main node.
    for (let i = 0; i < this.NUM_GAIN_NODES; ++i) {
      this.gainNodes[i] = gAudioContext.createGain();
      this.gainNodes[i].connect(this.mainNode);
      this.gainNodes[i].gain.value = 0;
    }

    // create an empty buffer to play for each gain node
    if (this.PLAY_EMPTY_BUFFER) {
      for (let i = 0; i < this.NUM_GAIN_NODES; ++i) {
        const emptyBuffer = gAudioContext.createBuffer(2, 22050, 44100);
        const emptyBufferSource = gAudioContext.createBufferSource();
        emptyBufferSource.loop = true;
        emptyBufferSource.buffer = emptyBuffer;
        emptyBufferSource.connect(this.gainNodes[i]);
        emptyBufferSource.start(gAudioContext.currentTime);
        this.emptyBuffers.push(emptyBuffer);
        this.emptyBufferSources.push(emptyBufferSource);
      }
    }
  }

  // gets called periodically (from setInterval) after start() has been called.
  _update = () => {
    if (!gAudioContext) return;

    // measure clock drift. Have the two clocks diverged over time?
    const curClockDelta = this.getTime() - gAudioContext.currentTime;
    const deltaDrift = this.driftFilter.insert(this.clockDelta - curClockDelta);
    console.log(`drift:${deltaDrift.toFixed(4)}`);

    // if they have, update playhead so that song is readjusted.
    // also update playhead if nothing is playing right now.
    if (Math.abs(deltaDrift) > this.CLOCK_DRIFT_THRESH || !this.activeSource) {
      this.clockDelta = curClockDelta;
      this.driftFilter.set(0);
      this._setPlayhead();
    }
  };

  _setPlayhead = () => {
    console.log(`SET PLAYHEAD()`);

    if (!gAudioContext) return;
    if (!this.buffer) {
      console.log('buffer is null. Bailing out');
      return;
    }
    const syncTime = this.getTime();
    const ctx_time = gAudioContext.currentTime;

    if (ctx_time === 0) {
      console.log('ctx time === 0. Bailing out');
      return;
    }

    if (syncTime === 0) {
      console.log('syncTime === 0. Bailing out');
      return;
    }

    // fade out the currently playing thing.
    if (this.activeSource) {
      this.activeSource.stop(ctx_time + this.FADE_TIME);
      //TODO does this delete and/or disconnect from its output node
      // automatically when we call stop?  otherwise, we need to set a timeout, and then do that.
      let gain_param = this.gainNodes[this.gainNodeIdx].gain;
      gain_param.cancelScheduledValues(ctx_time);
      gain_param.setValueAtTime(gain_param.value, ctx_time);
      gain_param.linearRampToValueAtTime(0, ctx_time + this.FADE_TIME);

      // set up for attaching to next node.
      this.gainNodeIdx += 1;
      this.gainNodeIdx %= this.NUM_GAIN_NODES;
    }

    // start playing a new thing.
    const delay_time = Math.max(this.startTime - syncTime, 0);
    const song_start = Math.max(syncTime - this.startTime, 0);
    console.log(` delay:${delay_time.toFixed(2)} song_start:${song_start.toFixed(2)}`);
    this.activeSource = playSound(
      this.buffer,
      this.gainNodes[this.gainNodeIdx],
      ctx_time + delay_time,
      song_start,
    );

    // fade up from 0 to 1
    let gain_param = this.gainNodes[this.gainNodeIdx].gain;
    gain_param.cancelScheduledValues(ctx_time);
    gain_param.setValueAtTime(gain_param.value, ctx_time);
    gain_param.linearRampToValueAtTime(1, ctx_time + this.FADE_TIME);
  };

  // start playback
  start = (startTime: number) => {
    console.log(`Song.start(${startTime})`);
    if (!gAudioContext) return;
    if (this.startTime !== 0) return;

    this.startTime = startTime;

    // set initial value for clock delta (which is looking for clock drift)
    if (gAudioContext.currentTime !== 0)
      this.clockDelta = this.getTime() - gAudioContext.currentTime;

    // set playhead to get things going.
    this._setPlayhead();

    // start polling.
    this.poller.start();
  };

  // stop playback
  stop = () => {
    console.log('Song.stop()');
    if (this.startTime === 0) return;

    this.startTime = 0; // zero means not playing

    if (this.activeSource) {
      this.play(false);
      this.activeSource.stop(this.FADE_TIME);
      this.activeSource = null;
    }

    this.poller.stop();
  };

  // ramp volume up or down
  play = (on: boolean) => {
    console.log(`Song.play(${on})`);
    if (!this.mainNode || !gAudioContext) return;

    // fade up or fade down?
    const gain = on ? this.NORMAL_GAIN : 0;

    this.mainNode.gain.cancelScheduledValues(gAudioContext.currentTime);
    this.mainNode.gain.setValueAtTime(this.mainNode.gain.value, gAudioContext.currentTime);
    this.mainNode.gain.linearRampToValueAtTime(
      gain,
      gAudioContext.currentTime + this.PLAY_TOGGLE_FADE,
    );
  };

  // if we want this song to cleanup its resources...
  release = () => {
    this.stop();
    // TODO - remove the components attached to gAudioContext?
  };

  getLoadProgress = () => {
    return this.loadProgress;
  };
}

// ----------------------------------------------------
// plays a clicks every second
//
export class Clicker {
  getTime: () => number;
  lastTime: number;
  clickSnd: AudioBuffer | null;
  gainNode: GainNode | null;
  poller: IntervalPoller;

  constructor(getTime: () => number) {
    this.getTime = getTime;
    this.lastTime = 0;
    this.clickSnd = null;
    this.gainNode = null;

    if (gAudioContext) {
      this.gainNode = gAudioContext.createGain();
      this.gainNode.gain.value = 0.05;
      this.gainNode.connect(gAudioContext.destination);
    }

    this.poller = new IntervalPoller(this._update, 60);

    // initiate load of click.wav
    new BufferLoader('audio/click.wav', this.finishedLoading);
  }

  start = () => {
    this.lastTime = this.getTime();
    this.poller.start();
  };

  stop = () => {
    this.poller.stop();
  };

  finishedLoading = (bufferList: AudioBuffer) => {
    this.clickSnd = bufferList;
  };

  _update = () => {
    if (!this.clickSnd || !this.gainNode) return;

    var now = this.getTime() % 2;

    if (now < this.lastTime) {
      playSound(this.clickSnd, this.gainNode);
    }

    this.lastTime = now;
  };
}

// time: when to start playing the sounds
// offset: how far into the audio buffer to start playing
const playSound = (buffer: AudioBuffer, dst: AudioNode, time = 0, offset = 0) => {
  console.log(`playSound(${time.toFixed(2)}, ${offset.toFixed(2)})`);
  if (!gAudioContext) return null;

  const source = gAudioContext.createBufferSource();
  source.buffer = buffer;
  source.connect(dst);
  source.start(time, offset);
  return source;
};

import * as React from 'react';
import styled from 'styled-components';

import { PlayButton } from './PlayButton';
import { Footer } from './Footer';
import { Page } from './Page';
import { Header } from './Header';
import { RingerVolume } from './RingerVolume';
import { TestTone } from '../lib/audio';

const NOTES = ['a', 'd', 'e', 'g'];

interface Props {
  onSetStatus: (status: string) => void;
}

export const SoundCheck = (props: Props) => {
  const { onSetStatus } = props;

  const [showNext, setShowNext] = React.useState(false);
  const [tone, setTone] = React.useState<TestTone | null>(null);

  // inform of status on mount
  React.useEffect(() => onSetStatus('check'), [onSetStatus]);

  if (!tone) {
    // pick a random note:
    const idx = Math.floor(Math.random() * NOTES.length);
    const note = NOTES[idx];
    const file = `/audio/tuning_notes/${note}.mp3`;
    setTone(new TestTone(file));
  }

  const down = () => {
    setShowNext(true);
    tone?.start();
  };

  const up = () => {
    tone?.stop();
  };

  return (
    <Page>
      <Header />
      <Main>
        <div>
          <h2>Sound Check</h2>
          <RingerVolume />
        </div>
        <div>
          <PlayButton onDown={down} onUp={up} type={'hand'} animate={true} />
          <p className='helpText'>Press + Hold + Listen</p>
        </div>
      </Main>
      <Footer bulletNum={0} showNext={showNext} nextUrl={'/part'} />
    </Page>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;

  // help text
  .helpText {
    color: rgba(255, 255, 255, 0.6);
    align-self: center;
  }

  @media (orientation: landscape) {
    flex-direction: row;
  }
`;

// </div>

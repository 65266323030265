import * as React from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  /** show flying notes or not */
  active: boolean;
  /** starting location of flying notes in pixels */
  x: number;
  /** starting location of flying notes in pixels */
  y: number;
  /** width of screen to calc flying note destinations */
  w: number;
  /** height of screen to calc flying note destinations */
  h: number;
}

// generate this many notes with a type of 1 or 2
const noteTypes = [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2];

export const FlyingNotes = (props: Props) => {
  const { active, x, y, w, h } = props;

  // memoize the notes so that they don't change when active changes.
  // only modify notes when the screen redraws
  // we use a random key to defeat browser caching problems.
  const notes = React.useMemo(
    () =>
      noteTypes.map((type, i) => {
        const params = { type, x, y, w, h };
        return <Note key={Math.random()} {...params} />;
      }),
    [x, y, w, h],
  );

  const style = { opacity: active ? 1 : 0 };
  return <StyledContainer style={style}>{notes}</StyledContainer>;
};

interface NoteProps {
  /** 1 or 2, to select from two different looks */
  type: number;
  /** starting position */
  x: number;
  /** starting position */
  y: number;
  /** screen width*/
  w: number;
  /** screen height */
  h: number;
}

const Note = (props: NoteProps) => {
  const { type, x, y, w, h } = props;

  // use a random angle to find a radial destination point
  const angle = Math.random() * 6.28; // 0 -> 2pi
  const xTo = Math.round(w * 0.6 * Math.cos(angle) + w * 0.6);
  const yTo = Math.round(h * 0.6 * Math.sin(angle) + h * 0.6);

  // duration of travel (stream animation)
  const streamDur = Math.random() * 2 + 1;

  // duration and amplitude of wobble (wave animation)
  const yOffset = Math.random() * 30;
  const waveDur = Math.random() * 5 + 1;

  // use one or two glyphs
  const inner =
    type === 1 ? <span>&#9835;&#xFE0E;</span> : <span>&#9835;&#xFE0E;&#9834;&#xFE0E;</span>;

  // set variable as in-line styles.
  const noteStyle = {
    'left': `${x}px`,
    'top': `${y}px`,
    '--left': `${x}px`,
    '--top': `${y}px`,
    '--y_from': `${y}px`,
    '--x-stream-to': `${xTo}px`,
    '--y-stream-to': `${yTo}px`,
    '--stream-duration': `${streamDur}s`,
    '--y-offset': `${yOffset}px`,
    '--wave-duration': `${waveDur}s`,
  };
  return <StyledNote style={noteStyle}>{inner}</StyledNote>;
};

// keyframes for traveling in a straight line to a final destination
const stream = keyframes`
  0% { left: var(--left); top: var(--top); }
  100% { left: var(--x-stream-to); top: var(--y-stream-to); }
`;
// 0% { transform: translate3d(0, 0, 0); }
// 100% { transform: translate3d(var(--x-stream-to), var(--y-stream-to), 0) } }

// keyframes for creating a wobble effect.
const wave = keyframes`
  0% { transform: translate3d(0, var(--y-offset), 0) }
  50% { transform: translate3d(0, calc(-1 * var(--y-offset)), 0) }
  100% { transform: translate3d(0, var(--y-offset), 0) }
`;

// container of all flying notes
const StyledContainer = styled.div`
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 1.44rem;
  pointer-events: none;
  transition: opacity 0.5s;
`;

// style for a particular flying note.
const StyledNote = styled.div`
  position: absolute;
  animation: var(--stream-duration) linear 0s infinite ${stream}; }

  span {
    position: absolute;
    animation: var(--wave-duration) ease-in-out 0s infinite ${wave}; }
  }
`;

import * as React from 'react';
import styled from 'styled-components';
import { CondData } from '../lib/tutti';

interface Props {
  condData: CondData;
}

export const Done = (props: Props) => {
  const { condData } = props;
  const bravoText = condData.bravoText;

  return (
    <StyledDone>
      <h1>Tutti</h1>
      <span>Bravo, {bravoText}</span>
    </StyledDone>
  );
};

const StyledDone = styled.div`
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    font-size: 600%;
  }
`;

// PartSys.js particle system

//-------------------------------------------------------------
// config variables for particle system behavior
const PSConfig = {
  maxParticles: 75,
  szStart: 2,
  szEnd: 12,
  lifeStep: 0.05,
};

//-------------------------------------------------------------
// individual particle. Animates size and alpha. Fixed location.
class Particle {
  x;
  y;
  clr;
  lifetime;

  constructor(x: number, y: number, clr: number[]) {
    this.x = x;
    this.y = y;
    this.clr = clr;
    this.lifetime = 0; // born at 0.0, dies at 1.0
  }

  draw(ctx: CanvasRenderingContext2D) {
    // animate based on particle lifetime
    const alpha = interp(this.lifetime, 1, 0);
    const sz = interp(this.lifetime, PSConfig.szStart, PSConfig.szEnd);

    const clr = colorArrayToRGBA([...this.clr, alpha]);
    ctx.fillStyle = clr;
    ctx.beginPath();
    ctx.arc(this.x, this.y, sz, 0, 2 * Math.PI);
    ctx.fill();

    // advance life
    this.lifetime += PSConfig.lifeStep;

    // return true if alive, false if dead.
    return this.lifetime < 1;
  }
}

//-------------------------------------------------------------
// particle system of expanding/fading circles.
export class PartSys {
  xSpan;
  ySpan;
  clr;
  particles;
  emitRate;
  emitCnt;

  constructor(x: number, y: number, w: number, h: number, clr: number[]) {
    this.xSpan = [x, x + w];
    this.ySpan = [y, y + h];
    this.clr = clr;
    this.particles = new Array(PSConfig.maxParticles).fill(null);
    this.emitRate = 0;
    this.emitCnt = 0;
  }

  setEmitRate(r: number) {
    this.emitRate = r;
  }

  draw(ctx: CanvasRenderingContext2D) {
    this.emitCnt += this.emitRate;

    for (var i = this.particles.length - 1; i >= 0; i--) {
      let particle = this.particles[i];

      // if particle exists, draw it. Check if it died.
      if (particle) {
        if (!particle.draw(ctx)) particle = null;
      }

      // particle slot is empty, perhaps emit a new one
      if (particle === null && this.emitCnt > 1) {
        this.emitCnt -= 1;

        // particle location, randomly chosen to lie within rectangular area
        var x = interp(Math.random(), this.xSpan[0], this.xSpan[1]);
        var y = interp(Math.random(), this.ySpan[0], this.ySpan[1]);

        this.particles[i] = new Particle(x, y, this.clr);
      }
    }
  }
}

//-------------------------------------------------------------
// helper functions

/** for input x range: [0,1] return value (y) in the range [y1,y2] */
const interp = (x: number, y1: number, y2: number) => x * (y2 - y1) + y1;

/** convert [r,g,b,a] to 'rgba(r,g,b,a)' */
const colorArrayToRGBA = (clr: number[]) => 'rgba(' + clr.join(',') + ')';

import * as React from 'react';
import styled from 'styled-components';
import bg from '../img/vizbg.png';
import { Song } from '../lib/audio';

import { CondData, Tutti } from '../lib/tutti';
import { useInterval } from '../lib/utils';
import { Done } from './Done';
import { Live } from './Live';
import { Setup } from './Setup';

interface Props {
  condData: CondData;
  tutti: Tutti;
}

export const Viz = (props: Props) => {
  const { tutti, condData } = props;
  const { mode } = condData;

  // start loading song right away:
  const song = React.useRef<Song | null>(null);
  React.useEffect(() => {
    if (!song.current) {
      console.log('new song');
      const getTime = () => tutti.getClock().getTime();
      song.current = new Song(getTime, `audio/score/backing_track.mp3`);
    }
    return () => {
      console.log('delete song');
      song.current?.release();
      song.current = null;
    };
  }, [tutti]);

  // keep track of song loading progress
  const [loadProg, setLoadProg] = React.useState(0);
  useInterval(() => {
    if (song.current) {
      setLoadProg(song.current.getLoadProgress());
    }
  }, 500);

  tutti.getClock().setOffset(condData.clockOffset);

  // play logic
  const playing = React.useRef(false);
  if (song.current && !playing.current && condData.startTime > 0) {
    song.current.start(condData.startTime);
    song.current.play(true);
    playing.current = true;
  }
  if (song.current && playing.current && condData.startTime === 0) {
    song.current.stop();
    playing.current = false;
  }

  // force clicking a button so audio will be sent.
  const [clickNeeded, setClickNeeded] = React.useState(true);
  const start = () => setClickNeeded(false);

  // select component based on mode:
  const comp = clickNeeded ? (
    <BigButton onClick={start}>START</BigButton>
  ) : mode === 'pre' || condData.mode === 'intro' || !song.current ? (
    <Setup tutti={tutti} condData={condData} />
  ) : mode === 'live' ? (
    <Live tutti={tutti} condData={condData} />
  ) : (
    <Done condData={condData} />
  );

  // display if song is still loading
  const loading =
    loadProg < 1 ? <Loading>{`Loading: ${(100 * loadProg).toFixed(0)}%`}</Loading> : null;

  return (
    <StyledViz>
      {comp}
      {loading}
    </StyledViz>
  );
};

const StyledViz = styled.div`
  background-image: url(${bg});
  background-position: center;

  // set div height to full window height so that bg image appears full screen
  height: 100vh;

  // default font for viz
  font-family: 'SuisseIntlMono';

  // font and svg color
  color: white;

  h1 {
    margin-top: 0px;
    margin-left: 3%;
    padding-top: 20px;
  }
`;

// centered button
const BigButton = styled.button`
  position: relative;
  width: 100px;
  height: 50px;
  margin: -25px -50px;
  top: 50%;
  left: 50%;
`;

// loading status
const Loading = styled.div`
  position: absolute;
  top: 10%;
  left: 40%;
`;

import * as React from 'react';
import styled from 'styled-components';

export const Header = () => {
  return <StyledHeader>Tutti</StyledHeader>;
};

const StyledHeader = styled.div`
  position: absolute;
  top: 0%;
  font-family: 'SuisseIntlMono';
  font-size: 150%;
  text-align: start;
  margin-top: 5px;
  margin-left: 5px;
`;

export const BigHeader = () => {
  return <StyledBigHeader>Tutti</StyledBigHeader>;
};

const StyledBigHeader = styled.div`
  position: absolute;
  top: 0%;

  font-family: 'SuisseIntlMono';
  font-size: 350%;
  text-align: center;
`;

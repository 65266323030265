import * as React from 'react';
import styled from 'styled-components';

interface Props {
  /** normalized [0-1] progress of loading bar. 1.0 will cause
   * loading bar to disappear
   */
  pct: number;
}

export const LoadingBar = (props: Props) => {
  const right = Math.ceil(props.pct * 100);
  const style = {
    opacity: right >= 100 ? 0 : 1,
    background: `linear-gradient(to right, #fff 0, #fff ${right}%, #fff0 ${right}%, #fff0 100%)`,
  };
  return <StyledBar style={style} />;
};

const StyledBar = styled.div`
  width: 100%;
  height: 1.4em;
  margin-top: 5%;
  border: 4px solid #fff;
  border-radius: 9999em;
  transition: opacity .5s;
}`;

import * as React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as Brass } from '../img/horn.svg';
import { ReactComponent as Perc } from '../img/marimba.svg';
import { ReactComponent as Winds } from '../img/clarinet.svg';
import { ReactComponent as Strings } from '../img/violin.svg';
import { ReactComponent as CheckMark } from '../img/check.svg';

import { Part } from '../lib/tutti';
import { partDisplayName } from '../lib/utils';

const backgroundColor = {
  perc: '#919c00',
  brass: '#fecb00',
  winds: '#0083a9',
  strings: '#cd202c',
};

const partIcon = {
  perc: <Perc className='inst' />,
  brass: <Brass className='inst' />,
  winds: <Winds className='inst' />,
  strings: <Strings className='inst' />,
};

interface Props {
  part: Part;
  selected: boolean;
  onSelect: (part: Part) => void;
}

export const PartButton = (props: Props) => {
  const { part, selected, onSelect } = props;

  const inst = partIcon[part];
  const name = partDisplayName[part];
  const color = backgroundColor[part];

  const noTouch = !('ontouchstart' in document.documentElement);

  const click = () => onSelect(part);

  return (
    <StyledButton
      color={color}
      selected={selected}
      onTouchStart={click}
      onMouseDown={noTouch ? click : undefined}
    >
      {inst}
      <span>{name}</span>
      <CheckMark className='check' />
    </StyledButton>
  );
};

const StyledButton = styled.div<{ color: string; selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 38vmin;
  width: 38vmin;
  margin: 4vmin;
  font-size: 4vmin;
  font-family: 'SuisseIntlMono';

  @media (orientation: landscape) {
    height: 35vmin;
    width: 35vmin;
  }

  background-color: ${(p) => p.color};
  color: #340;
  filter: brightness(0.8);

  transition: transform 0.25s;

  user-select: none;

  .check {
    position: absolute;
    left: 2vh;
    top: 2vh;
    color: white;
    opacity: 0;
  }

  .inst {
    margin-bottom: 2vmin;
  }

  ${(p) =>
    p.selected &&
    css`
      color: black;
      transform: scale(120%);
      filter: brightness(1);
      .check {
        opacity: 1;
      }
    `}
`;

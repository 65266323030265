import * as socketCluster from 'socketcluster-client';

interface FilterSample {
  local: number;
  ref: number;
  latency: number;
}

/** estimates the server clock based on collected data points and a local time */
class ClockEstimatorFilter {
  samples: FilterSample[];
  loc_ave;
  ref_ave;
  age;
  num_samples;

  constructor() {
    this.samples = [];
    this.loc_ave = 0;
    this.ref_ave = 0;

    this.age = 60;
    this.num_samples = 4;
  }

  numSamples() {
    return this.samples.length;
  }

  // add a new data point of local-time, reference-time, and the latency of this data point.
  insert(local: number, ref: number, latency: number) {
    // check if incoming data is very different from what we expect:
    if (this.samples.length > 0) {
      let delta = Math.abs(this.ref_ave - this.loc_ave - (ref - local));

      // console.log('delta', delta, 'num samples', this.samples.length);
      // if we are off by more than 10 seconds, something went wrong - so start over.
      if (delta > 10) {
        this.samples = [];
      }
    }

    // add sample
    this.samples.push({ local, ref, latency });

    // sort by latency
    this.samples.sort((a, b) => a.latency - b.latency);

    // remove samples older than this.age seconds
    const thresh = local - this.age;
    this.samples = this.samples.filter((x) => x.local > thresh);

    // finally, calc params based on sample data points:
    const num = Math.min(this.num_samples, this.samples.length);
    if (num) {
      let loc_sum = 0;
      let ref_sum = 0;
      for (let i = 0; i < num; i++) {
        let s = this.samples[i];
        loc_sum += s.local;
        ref_sum += s.ref;
      }
      this.loc_ave = loc_sum / num;
      this.ref_ave = ref_sum / num;
    }
  }

  // give a local time, return the server (reference) time
  get(local: number) {
    return this.ref_ave + local - this.loc_ave;
  }
}

interface LatencyData {
  localTime: number;
  travelDur: number;
}

//-----------------------------------------
// Client-server synchronized clock
//
export class SyncClock {
  socket;
  loadProgress;
  nextPingTime;
  offset;
  filter;
  latencySamples: LatencyData[];
  latencyIdx;
  stats;
  pollID;
  // testSamples;

  constructor(socket: socketCluster.SCClientSocket) {
    this.socket = socket;
    this.loadProgress = 0;
    this.nextPingTime = 0;
    this.offset = 0;

    this.filter = new ClockEstimatorFilter();

    // used only for data analysis
    // this.testSamples = [];

    this.latencySamples = [];
    this.latencyIdx = 0;

    this.stats = { interval: 0, min: 0, max: 0, ave: 0 };

    // handle pong msg (response to clockPing)
    socket.on('clockPong', (data) => {
      // console.log('clockPong', data);

      let localPing = data[0];
      let refTime = data[1];

      let localPong = this.getLocalTime();
      let travelDur = localPong - localPing;
      let localTime = (localPong + localPing) / 2;

      // refTime is the time of the server
      // localTime is the estimate of localTime at the moment when refTime was set.
      //   (assumes travel time to and fro are equal)
      this._process(localTime, refTime, travelDur);
    });

    // get started, polling every 200 ms
    this.pollID = setInterval(this._poll.bind(this), 200);
  }

  disconnect() {
    clearInterval(this.pollID);
  }

  setOffset(offset: number) {
    this.offset = offset;
  }

  // return the estimated server time. Returns 0 when not yet determined
  getTime() {
    if (this.loadProgress === 1) return this.offset + this.filter.get(this.getLocalTime());
    else return 0;
  }

  // get local time in seconds
  getLocalTime() {
    if (typeof performance !== 'undefined') return 0.001 * performance.now();
    else return 0.001 * Date.now();
  }

  getLoadProgress() {
    return this.loadProgress;
  }

  // returns min/max/ave latency times for the past N samples representing 'interval' duration of time.
  calcLatencyStats() {
    let tMin = 1000000000;
    let tMax = 0;
    let lMin = 1000000000;
    let lMax = 0;
    let lSum = 0;

    for (let i = 0; i < this.latencySamples.length; i++) {
      const t = this.latencySamples[i].localTime;
      const l = this.latencySamples[i].travelDur;

      tMin = Math.min(tMin, t);
      tMax = Math.max(tMax, t);
      lMin = Math.min(lMin, l);
      lMax = Math.max(lMax, l);
      lSum += l;
    }

    this.stats = {
      interval: tMax - tMin,
      min: lMin,
      max: lMax,
      ave: lSum / this.latencySamples.length,
    };
  }

  _poll() {
    const localNow = this.getLocalTime();

    if (localNow > this.nextPingTime) {
      // console.log('ping: ' + localNow.toFixed(3));
      this.socket.emit('clockPing', this.getLocalTime());
      this.nextPingTime = localNow + 3.0; // largest amount of time to wait before trying another ping.
    }
  }

  _process(localTime: number, refTime: number, travelDur: number) {
    // console.log('_process: ' + localTime.toFixed(3) + ' ' + refTime.toFixed(3) + ' ' + travelDur.toFixed(3));

    // insert new data into filter
    this.filter.insert(localTime, refTime, travelDur);

    // progress - we want 3 samples before providing an estimate
    const numSamples = this.filter.numSamples();
    this.loadProgress = numSamples / 3.0;
    if (this.loadProgress >= 1) this.loadProgress = 1;

    // store test samples for later data analysis
    // if (this.testSamples.length < 1000) {
    //   var estTime = this.filter.get( localTime );
    //   this.testSamples.push( [localTime, refTime, travelDur, estTime ] );
    // }

    // record travelDur / latency data for later analysis
    this.latencySamples[this.latencyIdx] = { localTime, travelDur };
    this.latencyIdx = (this.latencyIdx + 1) % 5;
    this.calcLatencyStats();

    // generate next pingpong:
    const pingDelay = numSamples > 20 ? 2.0 : numSamples > 10 ? 1.0 : 0.5;

    const delta = pingDelay + Math.random() * pingDelay * 0.1;
    this.nextPingTime = this.getLocalTime() + delta;
  }
}

import * as React from 'react';
import styled from 'styled-components';

interface Props {
  /** number of bullets to show */
  num: number;
  /** which of these is current */
  cur: number;
}

export const ProgressBullets = (props: Props) => {
  const { num, cur } = props;

  let items = [];
  for (let i = 0; i < num; ++i) {
    const curName = i === cur ? 'current' : undefined;
    items.push(
      <span className={curName} key={i}>
        •{' '}
      </span>,
    );
  }

  return <StyledBullets>{items}</StyledBullets>;
};

const StyledBullets = styled.span`
  font-size: 2em;
  color: #666;

  .current {
    color: #fff;
  }
}`;

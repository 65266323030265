import * as React from 'react';
import styled from 'styled-components';
import bg1 from '../img/bg1.svg';
import bg2 from '../img/bg2.svg';
import { Part } from '../lib/tutti';

interface Props {
  /** when an instrument is using this page, the background color can change */
  activePart?: Part;
  children?: React.ReactNode;
}

interface colorScheme {
  bgColor: string;
  light: string;
  dark: string;
  size: string;
}

const colorMap = {
  none: { bgColor: '#000', light: '#fff4', dark: '#fff0', size: '140% 140%' },
  winds: { bgColor: '#0083a9', light: '#fff8', dark: '#fff0', size: '100% 100%' },
  brass: { bgColor: '#fecb00', light: '#fff8', dark: '#fff0', size: '100% 100%' },
  strings: { bgColor: '#cd202c', light: '#fff8', dark: '#fff0', size: '100% 100%' },
  perc: { bgColor: '#919c00', light: '#fff8', dark: '#fff0', size: '100% 100%' },
};

export const Page = (props: Props) => {
  const { children, activePart } = props;

  const colors = colorMap[activePart || 'none'];

  return (
    <Background colors={colors}>
      <StyledPage>{children}</StyledPage>
    </Background>
  );
};

// black radial background - can be colored brightly during "active playing".
const Background = styled.div<{ colors: colorScheme }>`
  background-color: ${(p) => p.colors.bgColor};
  background-image: radial-gradient(
    ellipse at center,
    ${(p) => p.colors.light},
    ${(p) => p.colors.dark}
  );
  background-position: center;
  background-size: ${(p) => p.colors.size};
  transition: background-color 0.2s;
  overflow: hidden;

  user-select: none;
  height: 100%;
`;

const StyledPage = styled.div`
  position: relative;

  height: 100%;

  // dot pattern
  background-image: url(${bg1}), url(${bg2});
  background-repeat: repeat-y, repeat-y;
  background-position: 0 0, 100% 0;
  background-size: 33.3% auto, 33.3% auto;

  // set div height to full window height so that bg image appears full screen
  padding: 0;

  // font style
  font-family: 'SuisseIntl';
  text-align: center;

  // font and svg color
  color: white;
  svg path,
  g {
    fill: currentColor;
  }
}`;

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ProgressBullets } from './ProgressBullets';

interface Props {
  /** which screen this is (0 1 or 2) */
  bulletNum: number;
  /** show or hide the next button */
  showNext?: boolean;
  /** next button URL */
  nextUrl?: string;
}

export const Footer = (props: Props) => {
  const { bulletNum, showNext, nextUrl } = props;

  const navigate = useNavigate();
  const next = () => nextUrl && navigate(nextUrl);

  const noTouch = !('ontouchstart' in document.documentElement);

  return (
    <StyledFooter>
      <ProgressBullets num={3} cur={bulletNum} />
      <Next
        className='nextButton'
        show={!!showNext}
        onTouchEnd={next}
        onClick={noTouch ? next : undefined}
      >
        Next &rarr;
      </Next>
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  position: absolute;
  bottom: 0%;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Next = styled.button<{ show: boolean }>`
  position: absolute;
  right: 10px;
  width: max-content;

  border: none;
  outline: none;
  border-radius: 5px;
  padding: 0.5em 0.8em;

  background: #fff;
  color: #000;
  font-family: 'SuisseIntlMono';
  font-size: 1.2rem;

  visibility: ${(p) => (p.show ? 'visible' : 'hidden')};

  :active {
    transform: scale(120%);
  }
  transition: transform 0.15s;
`;

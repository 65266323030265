import * as React from 'react';
import styled from 'styled-components';
import songData from '../config/songdata';
import { CondData, Tutti } from '../lib/tutti';

interface Props {
  tutti: Tutti;
  condData: CondData;
}

export const PlayControls = (props: Props) => {
  const { tutti, condData } = props;
  const { mode, startTime } = condData;
  const playing = startTime > 0;

  const songLen = (songData.length * 60) / songData.bpm; // length in seconds
  return (
    <StyledControls>
      <StyledButton active={mode === 'pre'} onClick={() => tutti.set('mode', 'pre')}>
        Pre
      </StyledButton>
      <StyledButton active={mode === 'intro'} onClick={() => tutti.set('mode', 'intro')}>
        Intro
      </StyledButton>
      <StyledButton active={mode === 'live'} onClick={() => tutti.set('mode', 'live')}>
        Live
      </StyledButton>
      {'||'}
      <StyledButton active={mode === 'done'} onClick={() => tutti.set('mode', 'done')}>
        Done
      </StyledButton>
      <br />

      <StyledButton active={playing} onClick={() => tutti.startSong(songLen)}>
        Start
      </StyledButton>
      <StyledButton active={!playing} onClick={() => tutti.stopSong()}>
        Stop
      </StyledButton>
    </StyledControls>
  );
};

const StyledControls = styled.div``;

const StyledButton = styled.button<{ active: boolean }>`
  border: 1px solid black;
  background-color: ${(p) => (p.active ? 'red' : 'seashell')};
  width: 75px;
  height: 75px;
  border-radius: 100px;
  margin: 3px;
  padding: 0px;
  font-size: 1.4em;
  text-align: center;
  color: black;
`;

import * as React from 'react';
import styled from 'styled-components';
import { CondData, Tutti } from '../lib/tutti';
import { Info } from './Info';
import { Params } from './Params';
import { PlayControls } from './PlayControls';
import { Stats } from './Stats';

interface Props {
  tutti: Tutti | null;
  condData: CondData | null;
}

export const Conductor = (props: Props) => {
  const { tutti, condData } = props;

  // early out if condData is null (ie, server has not sent us anything yet)
  if (!condData || !tutti) {
    return (
      <StyledConductor>
        <h1>Tutti Conductor</h1>
        Connecting to server...
      </StyledConductor>
    );
  }

  return (
    <StyledConductor>
      <h2>Tutti Conductor</h2>
      <StyledItems>
        <Info tutti={tutti} condData={condData} />
        <PlayControls tutti={tutti} condData={condData} />
        <Stats tutti={tutti} />
        <Params tutti={tutti} condData={condData} />
      </StyledItems>
    </StyledConductor>
  );
};

const StyledConductor = styled.div`
  background-color: white;
  padding: 5px;
  height: 100%;
  h2 {
    text-align: center;
    margin: 0;
  }
  user-select: none;
`;

const StyledItems = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    border: 2px solid black;
    padding: 5px;
    margin: 5px;
  }
`;

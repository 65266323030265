import * as React from 'react';
import { Inst, Part, PartList } from './tutti';

/** From a part, choose a random instrument for that part */
export const chooseRandomInst = (part: Part) => {
  const options = PartList[part];
  const idx = Math.floor(Math.random() * options.length);
  return options[idx];
};

/** return the part associated with the given instrument, or null. */
export const partOfInst = (inst: Inst | null) => {
  if (inst === null) return null;

  for (const key in PartList) {
    const instList = PartList[key];
    if (instList.find((x) => x === inst)) return key as Part;
  }
  return null;
};

type periodicCB = () => void;

/** mapping from part to text string to display */
export const partDisplayName = {
  perc: 'Percussion',
  brass: 'Brass',
  winds: 'Woodwinds',
  strings: 'Strings',
};

// ----------------------------------------------------
// Simple class that does polling based on window.setInterval.
// Takes a period in milliseconds. Note that this polling scheme is
// approximate at best.
//
export class IntervalPoller {
  requestID: number | null;
  period: number;
  func: () => void;

  constructor(func: () => void, periodMS: number) {
    this.requestID = null;
    this.func = func;
    this.period = periodMS;
  }

  start = () => {
    if (this.requestID !== null) return;
    this.requestID = window.setInterval(this.func, this.period);
  };

  stop = () => {
    if (this.requestID === null) return;
    window.clearInterval(this.requestID);
    this.requestID = null;
  };
}

/**
 * hook for setting up an interval callback. This hook by itself does
 * not trigger state updates on every call.
 * @param callback the function to call back
 * @param period function callback period in MS. period = 0 will pause callbacks
 */
export function useInterval(callback: periodicCB, period: number) {
  const savedCallback = React.useRef<periodicCB>();

  // Remember the latest callback
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // this effect only changes if period changes.
  React.useEffect(() => {
    const tick = () => savedCallback.current?.();

    if (period !== 0) {
      const id = setInterval(tick, period);
      return () => clearInterval(id);
    }
  }, [period]);
}

/**
 * hook for setting up a callback every animation frame. This hook does
 * not trigger state updates.
 * @param callback function to call back every animation frame
 */
export function useAnimationFrame(callback: periodicCB) {
  const savedCallback = React.useRef<periodicCB>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // this effect is set up once at the beginning and does not change
  // based on anything (because of [] as the last arg)
  React.useEffect(() => {
    let id = 0;

    const tick = () => {
      if (savedCallback.current) savedCallback.current();
      id = window.requestAnimationFrame(tick);
    };

    id = window.requestAnimationFrame(tick);
    return () => cancelAnimationFrame(id);
  }, []);
}

// helper function to get window dimensions
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

/**
 * hook for returning the current window dimension. Updates automatically
 * as window is resized.
 * See https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
 * @returns { width, height }
 */
export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  React.useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

/**
 * Similar to React's useState hook, but adds functionality for automatically loading
 * and saving the state to local storage as well.
 *
 * The stored object must have a version number which should be
 * incremented whenever fields changes.
 *
 * Only use this hook once in at a high level. If used multiple times with the
 * same key, changes will not be reflected from one instance to the next.
 * Pass down the getter/setter or wrap this in a context instead.
 *
 * @param key: key name for storing this data
 * @param initialState: default values for the data structure to store. Must have a version field.
 * @returns
 */
export function useLocalStorage<T extends { version: number }>(
  key: string,
  initialState: T,
): [T, (s: T) => void] {
  // cached value that has been loaded from storage
  const cached = React.useRef<{ key: string; data: T }>();

  // returns the value from storage, or if not found or not valid, returns default
  const storedState = () => {
    // cached because we loaded it once already, so return that.
    if (cached.current?.key === key) return cached.current.data;

    // default value (in case we cannot retrieve anything useful)
    cached.current = { key, data: initialState };

    // retrieve from local storage
    const stateString = localStorage.getItem(key);
    // console.log('loading', key, stateString);

    // did we get something we can use?
    if (stateString) {
      const parsedState: T = JSON.parse(stateString);
      if (initialState.version === parsedState.version) {
        cached.current = { key, data: parsedState };
      } else {
        // remove key if there is a version mismatch
        localStorage.removeItem(key);
      }
    }

    // returned what we have in cache
    return cached.current.data;
  };

  // holds state that is set by client calling the setter function
  // once newState is set, that value is returned instead of the cached value
  const [newState, setNewState] = React.useState<T | undefined>(undefined);

  // set state and also store in local storage
  const setState = React.useCallback(
    (s: T) => {
      // console.log('saving', key, JSON.stringify(s));
      localStorage.setItem(key, JSON.stringify(s));
      setNewState(s);
    },
    [key],
  );

  // prefer newState if set; otherwise go with stored state.
  const state = newState || storedState();
  return [state, setState];
}

import { SyncClock } from './syncclock';
import * as socketCluster from 'socketcluster-client';
import { UAParser } from 'ua-parser-js';

export type Part = 'perc' | 'brass' | 'winds' | 'strings';
export type Inst =
  | 'glockenspiel'
  | 'marimba'
  | 'vibraphone'
  | 'horn'
  | 'trumpet'
  | 'trombone'
  | 'clarinet'
  | 'flute'
  | 'oboe'
  | 'violin'
  | 'viola'
  | 'cello';

export const PartList: { [k: string]: Inst[] } = {
  perc: ['glockenspiel', 'marimba', 'vibraphone'],
  brass: ['horn', 'trumpet', 'trombone'],
  winds: ['clarinet', 'flute', 'oboe'],
  strings: ['violin', 'viola', 'cello'],
};

export type TuttiMode = 'pre' | 'intro' | 'live' | 'done';
export type PlayerPage = 'check' | 'part' | 'inst' | 'done';

export interface CondData {
  // main parameters:
  startTime: number;
  songLen: number;
  mode: TuttiMode;
  clockOffset: number;

  // text to show on Viz screen
  bravoText: string;
  wifiText: string;

  // debugging
  autoPlay: boolean;
  clickState: boolean;
}

export interface StatusData {
  part: { [k: string]: number };
  page: { [k: string]: number };
}

export const defaultStatus: StatusData = {
  part: {},
  page: {},
};

// TODO
export interface ActivityData {}

type CondDataCB = (data: CondData) => void;
type StatusCB = (data: StatusData) => void;
type ActivityLogCB = (data: ActivityData) => void;

export class Tutti {
  socket: socketCluster.SCClientSocket;
  clock: SyncClock;
  condDataCallbacks: CondDataCB[];
  condData: CondData | null;
  activityLogFn: StatusCB | null;
  statusCountsFn: StatusCB | null;

  // if botTest, this is run from node.js as part of many simulated clients
  // so socket cluster must be configured to allow for this.
  constructor(host: string, port: string, botTest = false) {
    // console.log(`new Tutti(${host}:${port})`);

    this.condDataCallbacks = []; // callback functions for when condData changes
    this.activityLogFn = null;
    this.statusCountsFn = null;
    this.condData = null;

    const options: socketCluster.SCClientSocket.ClientOptions = {
      hostname: host,
      port: parseInt(port),
      secure: port === '443', // ok. a bit of a hack, but works with standard deployment on heroku to enable wss://
      multiplex: !botTest,
    };

    this.socket = socketCluster.create(options);
    this._setupMessageHandlers();

    this.clock = new SyncClock(this.socket);

    // subscribe to tutti channel
    const channel = this.socket.subscribe('tutti');
    channel.watch((msg) => {
      if ('condData' in msg) this._broadcastCondData(msg.condData);
    });

    // get audio latency based on user agent info:
    const parser = new UAParser();
    const userData = {
      os: parser.getOS(),
      device: parser.getDevice(),
      browser: parser.getBrowser(),
    };
    this.socket.emit(
      'getLatency',
      userData,
      (err: Error, latencyData: { latency: number }) => {
        console.log('latency response:', latencyData, err);
        if (!err) {
          const { latency } = latencyData;
          this.clock.setOffset(latency);
        }
      },
    );
  }

  disconnect() {
    const state = this.socket.getState();
    // console.log(`Tutti disconnect. socket state ${state}`);
    if (state === 'open') {
      this.clock.disconnect();
      this.socket.unsubscribe('tutti');
      this.socket.disconnect();
    }
  }

  // add a handler to hear about when condData changes
  onCondData(fn: CondDataCB) {
    this.condDataCallbacks.push(fn);
  }

  // returns server time, or 0 if time is not ready
  getClock() {
    return this.clock;
  }

  // conductor only (ie, state modifiers):
  startSong(length: number) {
    const songLen = length;

    const now = this.clock.getTime();
    if (now === 0) {
      console.log('clock not ready');
      return;
    }
    const startTime = now + 2; // start 2 seconds from now
    this.socket.emit('startSong', { startTime, songLen });
  }

  stopSong() {
    this.socket.emit('stopSong');
  }

  toggle(key: 'autoPlay' | 'clickState') {
    if (this.condData === null) return;
    this.socket.emit('setAndBroadcast', [key, !this.condData[key]]);
  }

  // set a key to a value
  set(key: string, value: boolean | string | number | TuttiMode) {
    if (this.condData === null) return;

    if (key in this.condData) {
      this.socket.emit('setAndBroadcast', [key, value]);
    } else {
      console.log(`set(): ${key} not found in condData`);
    }
  }

  // set status: player sets status about themselves

  setStatus(type: string, status: string) {
    this.socket.emit('setStatus', [type, status]);
  }

  // receive server status data
  getStatusCounts(fn: StatusCB) {
    if (this.statusCountsFn !== null) {
      console.log('err: getting status counts already in progress');
      return;
    }
    this.statusCountsFn = fn;
    this.socket.emit('getStatusCounts');
  }

  // receive activity log:
  getActivityLog(fn: ActivityLogCB) {
    if (this.activityLogFn !== null) {
      console.log('err: getting activity already in progress');
      return;
    }
    this.activityLogFn = fn;
    this.socket.emit('getActivityLog');
  }

  // private functions:

  _setupMessageHandlers() {
    this.socket.on('condData', (data) => this._broadcastCondData(data));

    this.socket.on('statusCounts', (data) => {
      // console.log('statusCounts:', data);

      if (this.statusCountsFn) {
        let status = defaultStatus;
        if ('part' in data) status = { ...status, part: data.part };
        if ('page' in data) status = { ...status, page: data.page };

        this.statusCountsFn(status);
        this.statusCountsFn = null;
      }
    });

    this.socket.on('activityLog', (data) => {
      if (this.activityLogFn) {
        this.activityLogFn(data);
        this.activityLogFn = null;
      }
    });
  }

  _broadcastCondData(data: CondData) {
    this.condData = data;
    this.condDataCallbacks.forEach((fn) => fn(data));
  }
} // class Tutti

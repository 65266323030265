import * as React from 'react';
import styled from 'styled-components';
import { StatusData, Tutti } from '../lib/tutti';
import { useInterval } from '../lib/utils';

interface Props {
  tutti: Tutti;
}

const UPDATE_PERIOD = 1000;

export const Stats = (props: Props) => {
  const { tutti } = props;

  // local state for stats as they get updated.
  const [stats, setStats] = React.useState<StatusData | null>(null);

  // update stats by asking tutti server every 1000ms.
  useInterval(() => {
    tutti.getStatusCounts((data) => setStats(data));
  }, UPDATE_PERIOD);

  // sort
  var partList = stats && 'part' in stats ? Object.entries(stats.part) : [];
  partList.sort();
  const parts = partList.map(listMap);

  var pageList = stats && 'page' in stats ? Object.entries(stats.page) : [];
  pageList.sort();
  const pages = pageList.map(listMap);

  const numPlayers = partList.reduce((sum, value) => {
    return sum + value[1];
  }, 0);

  return (
    <StyledStats>
      <div className='stat-list'>
        <h3>Parts</h3>
        <ul>{parts}</ul>
      </div>
      <div className='stat-list'>
        <h3>State</h3>
        <ul>{pages}</ul>
      </div>
      <div>
        <h3>Total</h3>
        <p>{numPlayers}</p>
      </div>
    </StyledStats>
  );
};

// process stats:
// helper function to convert list into html
const listMap = (item: [string, number], idx: number) => {
  return (
    <p key={idx}>
      {item[0]}: {item[1]}
    </p>
  );
};

const StyledStats = styled.div`
  display: flex;
  flex-direction: row;

  .stat-list {
    width: 110px;
  }

  h3 {
    margin: 0px;
  }
  ul {
    margin: 0px;
    padding-left: 0px;
  }
  p {
    margin: 0px;
  }
`;

import * as React from 'react';
import * as Audio from './lib/audio';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Conductor } from './cond';
import { CondData, Inst, Part, Tutti } from './lib/tutti';
import { chooseRandomInst, partOfInst, useLocalStorage } from './lib/utils';
import { Intro, Instrument, PartSelect, SoundCheck } from './player';
import { Viz } from './viz';
import { Page } from './player/Page';
import { createGlobalStyle } from 'styled-components';

// data stored in local storage
interface UserData {
  version: 2;
  inst: Inst | null;
}

const HOST = process.env.REACT_APP_HOST || '';
const PORT = process.env.REACT_APP_PORT || '';

export const App = () => {
  // conductor data state
  const [data, setData] = React.useState<CondData | null>(null);

  // current inst
  const [userData, setUserData] = useLocalStorage<UserData>('tutti', {
    version: 2,
    inst: null,
  });

  // preTouch: show UI for user to do a short button tap to move on.
  // necessary because a long-press, which is how people normally press the button,
  // will not actually disable the browser audio block.
  const [preTouch, setPreTouch] = React.useState(true);

  // current location (URL in browser)
  const { pathname } = useLocation();

  // tutti and audio initialization
  const tutti = React.useRef<Tutti | null>(null);
  React.useEffect(() => {
    tutti.current = new Tutti(HOST, PORT);
    tutti.current.onCondData((data: CondData) => setData(data));
    Audio.init();

    return () => {
      tutti.current?.disconnect();
    };
  }, []);
  const tc = tutti.current;

  // user is setting part (which actually picks a random inst)
  const selectPart = (part: Part) => {
    setPreTouch(false);
    const inst = chooseRandomInst(part);
    setUserData({ ...userData, inst });
  };

  const part = partOfInst(userData.inst);

  // inform server of status change when part or pathname changes.
  React.useEffect(() => {
    // set chosen instrument part, but only if we aren't viz or cond
    const v = pathname.indexOf('viz') !== -1;
    const c = pathname.indexOf('cond') !== -1;
    const name = v || c ? 'admin' : part || 'none';
    tutti.current?.setStatus('part', name);
  }, [part, pathname]);

  const setPageStatus = React.useCallback(
    (status: string) => {
      console.log('set pagestatus', status);
      tc?.setStatus('page', status);
    },
    [tc],
  );

  // clients debugging: click state.
  const clickState = data?.clickState || false;
  const clicker = React.useRef<Audio.Clicker | null>();
  React.useEffect(() => {
    if (!clicker.current && clickState && tc) {
      clicker.current = new Audio.Clicker(() => tc.getClock().getTime());
      clicker.current.start();
    }
    if (clicker.current && !clickState) {
      clicker.current.stop();
      clicker.current = null;
    }
  }, [clickState, tc]);

  //--------------------------------------------
  // Which page to display

  // intro page depends on mode and preTouch

  const preTouchOK =
    preTouch && data && data.mode !== 'pre' ? () => setPreTouch(false) : undefined;

  const introPage =
    !data || data.mode === 'pre' || preTouch ? (
      <Intro onSetStatus={setPageStatus} onOK={preTouchOK} />
    ) : (
      <SoundCheck onSetStatus={setPageStatus} />
    );

  // part select also depends on mode
  const partSelect =
    data?.mode !== 'pre' ? (
      <PartSelect part={part} onSelect={selectPart} onSetStatus={setPageStatus} />
    ) : (
      <BackToStart />
    );

  // inst has a few choices depending on state:
  const instrument =
    data?.mode === 'pre' || !userData.inst ? (
      <BackToStart />
    ) : !data || !tc ? (
      <Page />
    ) : preTouch ? (
      <Intro onOK={preTouchOK} />
    ) : (
      <Instrument
        tutti={tc}
        condData={data}
        inst={userData.inst}
        onSetStatus={setPageStatus}
      />
    );

  const viz = !data || !tc ? <Page /> : <Viz tutti={tc} condData={data} />;

  return (
    <>
      <GlobalStyle />
      <Routes>
        <Route path='/' element={introPage} />
        <Route path='/part' element={partSelect} />
        <Route path='/inst' element={instrument} />
        <Route path='/cond' element={<Conductor tutti={tc} condData={data} />} />
        <Route path='/viz' element={viz} />
      </Routes>
    </>
  );
};

const BackToStart = () => {
  return <Navigate to='/' />;
};

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    background-color: black;
  }

  body {
    touch-action: manipulation;
  }

  * {
    box-sizing: border-box;
  }

  #root {
    height: 100%;
    max-height: 100%;
  }
`;

import * as React from 'react';
import styled from 'styled-components';
import { CondData, Tutti } from '../lib/tutti';
import { useAnimationFrame } from '../lib/utils';

interface Props {
  tutti: Tutti;
  condData: CondData;
}

export const Info = (props: Props) => {
  const clock = props.tutti.getClock();
  const condData = props.condData;

  // update time every animation frame
  const [time, setTime] = React.useState(0);
  useAnimationFrame(() => setTime(clock.getTime()));

  // build text
  const buildTxt = process.env.REACT_APP_BUILD_TEXT || '';

  // times
  const startTime = condData.startTime;
  const elapsedTime = startTime === 0 ? 0 : time - startTime;

  // ping times
  let { interval, min, max, ave } = clock.stats;
  interval = Math.round(interval);
  min = Math.round(1000 * min);
  max = Math.round(1000 * max);
  ave = Math.round(1000 * ave);
  const pingText = `@${interval}: ${min} / ${ave} / ${max}`;

  return (
    <StyledInfo>
      <label>Build: {buildTxt}</label> <br />
      <label>Server: {time.toFixed(2)}</label> <br />
      <div className='row'>
        <label className='spaced'>Start: {startTime.toFixed(2)}</label>
        <label className='spaced'>Elapsed: {elapsedTime.toFixed(2)}</label>
      </div>
      <label>Ping: {pingText}</label> <br />
    </StyledInfo>
  );
};

const StyledInfo = styled.div`
  .row {
    display: flex;
    flex-direction: row;
  }
  .spaced {
    width: 140px;
  }
`;
